.text-50-50 {
}

/* from-small,tablet-p,min-width-768px */
@media (min-width: 48rem) {
	.text-50-50 {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
	}
}

/* from-small,tablet-p,min-width-768px */
@media (min-width: 48rem) {
	.text-50-50--align-end {
		justify-content: flex-end;
	}
}

/* up-to-small,max-width-767px */
@media (max-width: 47.9375rem) {
	.text-50-50__column:not(:last-child) {
		margin-bottom: var(--space-45--rem);
	}
}

/* from-small,tablet-p,min-width-768px */
@media (min-width: 48rem) {
	.text-50-50__column {
		width: calc(50% - var(--column-gap) / 2);
	}
}
