/* #region Soehne */
@font-face {
	font-family: "Soehne";
	src: url("../fonts/soehne-buch-kursiv.woff2") format("woff2");
	font-style: italic;
	font-weight: 400;
}

@font-face {
	font-family: "Soehne";
	src: url("../fonts/soehne-buch.woff2") format("woff2");
	font-style: normal;
	font-weight: 400;
}

@font-face {
	font-family: "Soehne";
	src: url("../fonts/soehne-dreiviertelfett-kursiv.woff2") format("woff2");
	font-style: italic;
	font-weight: 700;
}

@font-face {
	font-family: "Soehne";
	src: url("../fonts/soehne-dreiviertelfett.woff2") format("woff2");
	font-style: normal;
	font-weight: 700;
}
/* #endregion Soehne */

/* #region Soehne Mono */
@font-face {
	font-family: "Soehne Mono";
	src: url("../fonts/soehne-mono-buch-kursiv.woff2") format("woff2");
	font-style: italic;
	font-weight: 400;
}

@font-face {
	font-family: "Soehne Mono";
	src: url("../fonts/soehne-mono-buch.woff2") format("woff2");
	font-style: normal;
	font-weight: 400;
}
/* #endregion Soehne Mono */

/* #region Soehne Schmal */
@font-face {
	font-family: "Soehne Schmal";
	src: url("../fonts/soehne-schmal-fett-kursiv.woff2") format("woff2");
	font-style: italic;
	font-weight: 700;
}

@font-face {
	font-family: "Soehne Schmal";
	src: url("../fonts/soehne-schmal-fett.woff2") format("woff2");
	font-style: normal;
	font-weight: 700;
}
/* #endregion Soehne Schmal */
