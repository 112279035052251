.wrapper {
	box-sizing: border-box;
	padding-right: var(--space-20--px);
	padding-left: var(--space-20--px);
	width: 100%;
}

/* from-small,tablet-p,min-width-768px */
@media (min-width: 48rem) {
	.wrapper {
		padding-right: var(--space-60--px);
		padding-left: var(--space-60--px);
	}
}

/* from-large,min-width-1440px */
@media (min-width: 90rem) {
	.wrapper {
		margin-right: auto;
		margin-left: auto;
		padding-right: 0;
		padding-left: 0;
		max-width: 62.1875rem;
	}
}
