.track-list {
	color: var(--color-black);
}

.track-list__item:not(:last-child) {
	margin-bottom: var(--space-1--rem);
}

/* #region Trigger */
.track-list__trigger {
	-mrh-resets: button; /* button reset */
	background-color: var(--color-light-grey);
	padding: var(--space-10--rem) var(--space-10--px);
	width: 100%;
}

/* from-small,tablet-p,min-width-768px */
@media (min-width: 48rem) {
	.track-list__trigger {
		padding: var(--space-20--rem) var(--space-20--px);
	}
}

.track-list__trigger:hover .track-list__trigger__title {
	opacity: 0.5;
	text-decoration: none;
}

.track-list__trigger:hover .track-list__trigger__specs p {
	opacity: 0.5;
}

.track-list__trigger[aria-expanded="true"] {
	background-color: var(--color-sulphur-yellow);
}

.track-list__trigger__inner {
	pointer-events: none;
	text-align: left;
}

/* from-small,tablet-p,min-width-768px */
@media (min-width: 48rem) {
	.track-list__trigger__inner {
		display: flex;
		justify-content: space-between;
	}
}

.track-list__trigger__title {
	margin-top: var(--space-10--rem);
	text-decoration: underline;
}

/* #region Specs */
.track-list__trigger__specs {
	display: flex;
	margin-top: var(--space-10--rem);
}

/* from-small,tablet-p,min-width-768px */
@media (min-width: 48rem) {
	.track-list__trigger__specs {
		flex-basis: 30%;
		flex-grow: 0;
		flex-shrink: 0;
		margin-top: 0;
		margin-left: var(--space-10--px);
		width: 30%;
	}

	.track-list__trigger__specs :first-child {
		margin-right: var(--space-10--px);
	}
}

/* from-large,min-width-1440px */
@media (min-width: 90rem) {
	.track-list__trigger__specs {
		max-width: 15.626rem;
	}
}

.track-list__trigger__specs div {
	width: 50%;
}

.track-list__trigger__specs p {
	margin-top: var(--space-10--rem);
}
/* #endregion Specs */
/* #endregion Trigger */

/* #region Panel */
.track-list__panel {
	background-color: var(--color-sulphur-yellow);
	padding-right: var(--space-10--px);
	padding-left: var(--space-10--px);
}

/* from-small,tablet-p,min-width-768px */
@media (min-width: 48rem) {
	.track-list__panel {
		padding-right: var(--space-20--px);
		padding-left: var(--space-20--px);
	}
}

/* Top */
.track-list-panel__top {
}

/* from-small,tablet-p,min-width-768px */
@media (min-width: 48rem) {
	.track-list-panel__top {
		display: flex;
		margin-bottom: calc(var(--space-25--rem) * 2);
		padding-top: var(--space-30--rem);
		justify-content: space-between;
	}
}

.track-list-panel__top .track-list__panel__item p {
	margin-top: var(--space-10--rem);
}

/* Bottom */
.track-list-panel__bottom {
	margin-top: var(--space-30--px);
	padding-bottom: var(--space-20--rem);
}

/* from-small,tablet-p,min-width-768px */
@media (min-width: 48rem) {
	.track-list-panel__bottom {
		margin-top: 0;
	}
}

.track-list-panel__bottom__inner {
	margin-top: var(--space-10--rem);
}

/* up-to-small,max-width-767px */
@media (max-width: 47.9375rem) {
	.track-list-panel__bottom__inner .track-list__panel__item:not(:first-child) {
		margin-top: var(--space-20--rem);
	}
}

/* from-small,tablet-p,min-width-768px */
@media (min-width: 48rem) {
	.track-list-panel__bottom__inner {
		display: flex;
	}

	.track-list-panel__bottom__inner .track-list__panel__item:not(:last-child) {
		margin-right: var(--space-30--px);
	}
}

/* from-large,min-width-1440px */
@media (min-width: 90rem) {
	.track-list-panel__bottom__inner {
		justify-content: space-between;
	}
}

/* #region Panel Item */
.track-list__panel__item {
}

/* from-small,tablet-p,min-width-768px */
@media (min-width: 48rem) {
	.track-list__panel__item {
		width: 50%;
	}
}

/* from-large,min-width-1440px */
@media (min-width: 90rem) {
	.track-list__panel__item {
		max-width: 15.626rem;
	}
}

.track-list__panel__item--track-info {
	margin-top: var(--space-20--rem);
}

/* from-small,tablet-p,min-width-768px */
@media (min-width: 48rem) {
	.track-list__panel__item--track-info {
		margin-top: 0;
	}
}

/* from-large,min-width-1440px */
@media (min-width: 90rem) {
	.track-list__panel__item--track-info {
		max-width: 15.626rem;
	}
}

.track-list__panel__item--description {
	padding-top: var(--space-20--rem);
}

/* from-small,tablet-p,min-width-768px */
@media (min-width: 48rem) {
	.track-list__panel__item--description {
		margin-right: var(--space-30--px);
		padding-top: 0;
	}
}

/* from-large,min-width-1440px */
@media (min-width: 90rem) {
	.track-list__panel__item--description {
		margin-right: 120px;
		width: 100%;
		max-width: 100%;
	}
}

.track-list__nutrition__title {
	text-transform: uppercase;
}

.track-list__nutrition__title {
	margin-bottom: var(--space-20--rem) 0;
}
/* #endregion Panel Item */
/* #endregion Panel */
