.text-with-carousel {
}

/* from-small,tablet-p,min-width-768px */
@media (min-width: 48rem) {
	.text-with-carousel {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
	}
}

/* from-small,tablet-p,min-width-768px */
@media (min-width: 48rem) {
	.text-with-carousel--align-end {
		justify-content: flex-end;
	}
}

/* up-to-small,max-width-767px */
@media (max-width: 47.9375rem) {
	.text-with-carousel__column:not(:last-child) {
		margin-bottom: var(--space-15--rem);
	}
}

/* from-small,tablet-p,min-width-768px */
@media (min-width: 48rem) {
	.text-with-carousel__column {
		width: calc(50% - var(--column-gap) / 2);
	}
}

/* from-large,min-width-1440px */
@media (min-width: 90rem) {
	.text-with-carousel__column {
		width: calc(50% - var(--column-gap) / 2);
	}

	.text-with-carousel__column--left {
		width: calc(50% - var(--space-100--px));
	}
}
