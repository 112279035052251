.hero-homepage {
	position: relative;
	min-height: 29.375rem;
}

/* from-small,tablet-p,min-width-768px */
@media (min-width: 48rem) {
	.hero-homepage {
		min-height: 21.25rem;
	}
}

/* from-large,min-width-1440px */
@media (min-width: 90rem) {
	.hero-homepage {
		min-height: 37.5rem;
	}
}

.hero-homepage__background {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
}

.hero-homepage__background__image {
	width: 100%;
	height: auto;
}

@supports (object-fit: cover) {
	.hero-homepage__background__image {
		object-fit: cover;
		height: 100%;
	}
}
