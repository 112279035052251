:root {
	/* #region Colors */
	--color-foreground: rgb(16 55 70);
	--color-background: rgb(255 255 255);
	/*  */
	--color-black: rgb(0 0 0);
	--color-grey: rgb(168 168 168);
	--color-light-grey: rgb(235 235 235);
	--color-slate: rgb(16 55 70);
	--color-sulphur-yellow: rgb(245 245 184);
	--color-white: rgb(255 255 255);
	/*  */
	--color-alpha-grey-70: rgba(168 168 168 / 0.7);
	--color-alpha-slate-70: rgba(16 55 70 / 0.7);
	--color-alpha-sulphur-yellow-70: rgba(245 245 184 / 0.7);
	--color-alpha-white-70: rgba(255 255 255 / 0.7);
	/*  */
	--color-warning: rgb(242 149 0); /* Orange - contrast 4.5 & 7 */
	--color-error: rgb(207 0 0); /* Red - contrast 4.5 & 7 */
	/* #endregion Colors */

	/* #region Typography */
	--font-sans: "Soehne", "Trebuchet MS", sans-serif;
	--font-sans-mono: "Soehne Mono", "Courier New", sans-serif;
	--font-sans-narrow: "Soehne Schmal", "Impact", sans-serif;
	/* #endregion Typography */

	/* #region Spacers */
	--space-1--px: 1px;
	--space-1--rem: 0.0625rem;
	--space-10--px: 10px;
	--space-10--rem: 0.625rem;
	--space-15--px: 15px;
	--space-15--rem: 0.9375rem;
	--space-20--px: 20px;
	--space-20--rem: 1.25rem;
	--space-25--px: 25px;
	--space-25--rem: 1.5625rem;
	--space-30--px: 30px;
	--space-30--rem: 1.875rem;
	--space-40--px: 40px;
	--space-40--rem: 2.5rem;
	--space-45--px: 45px;
	--space-45--rem: 2.8125rem;
	--space-60--px: 60px;
	--space-60--rem: 3.75rem;
	--space-65--px: 65px;
	--space-65--rem: 4.0625rem;
	--space-90--px: 90px;
	--space-90--rem: 5.625rem;
	--space-100--px: 100px;
	--space-100--rem: 6.25rem;
	--space-180--px: 180px;
	--space-180--rem: 11.25rem;
	/* #endregion Spacers */

	/* #region Border Radius */
	--radius-10--px: 10px;
	/* #endregion Border Radius */

	/* #region Column gaps for layout grid */
	--column-gap: 20px;
	/* #endregion Column gaps for layout grid */

	/* #region Z-Indices */
	--z-index-modal-dialog: 100;
	/* #endregion Z-Indices */
}

/* from-large,min-width-1440px */
@media (min-width: 90rem) {
	:root {
		/* #region Column gaps for layout grid */
		--column-gap: 25px;
		/* #endregion Column gaps for layout grid */
	}
}
