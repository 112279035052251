.cta-list {
}

/* from-large,min-width-1440px */
@media (min-width: 90rem) {
	.cta-list {
		margin-right: auto;
		margin-left: auto;
		/* spans 10 cols */
		width: 51.5625rem;
	}
}

.cta-list__item:not(:last-child) {
	margin-bottom: var(--space-45--rem);
}

/* from-small,tablet-p,min-width-768px */
@media (min-width: 48rem) {
	.cta-list__item {
		display: flex;
		justify-content: space-between;
	}
}

/* from-large,min-width-1440px */
@media (min-width: 90rem) {
	.cta-list__item:not(:last-child) {
		margin-bottom: var(--space-90--rem);
	}
}

/* from-small,tablet-p,min-width-768px */
@media (min-width: 48rem) {
	.cta-list__item__image,
	.cta-list__item__text {
		flex-basis: calc(50% - var(--column-gap) / 2);
		flex-grow: 0;
		flex-shrink: 0;
	}
}

/* #region Image */

/* from-small,tablet-p,min-width-768px */
@media (min-width: 48rem) {
	.cta-list__item:nth-child(even) .cta-list__item__image {
		order: 2;
	}
}

.cta-list__item__image__source {
	width: 100%;
}
/* #endregion Image */

/* #region Text */
.cta-list__item__text {
	margin-top: var(--space-45--rem);
	text-align: center;
}

/* from-small,tablet-p,min-width-768px */
@media (min-width: 48rem) {
	.cta-list__item__text {
		display: flex;
		flex-direction: column;
		margin-bottom: var(--space-45--rem);
		justify-content: center;
		align-items: center;
	}

	.cta-list__item:nth-child(even) .cta-list__item__text {
		order: 1;
	}
}
/* #endregion Text */

/* #region Link */
.cta-list__item__link {
	margin-top: var(--space-10--rem);
}

/* from-large,min-width-1440px */
@media (min-width: 90rem) {
	.cta-list__item__link {
		margin-top: var(--space-20--rem);
	}
}
/* #endregion Link */
