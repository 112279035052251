.navigation-overlay {
	box-sizing: border-box;
	padding-bottom: var(--space-45--rem);
	position: relative;
	height: 100%;
	min-height: 100vh;
}

.navigation-overlay__secondary-menu {
	padding-top: var(--space-20--rem);
	padding-bottom: var(--space-45--rem);
}

/* from-small,tablet-p,min-width-768px */
@media (min-width: 48rem) {
	.navigation-overlay__secondary-menu {
		padding-top: var(--space-10--rem);
		padding-bottom: var(--space-90--rem);
	}
}

/* from-large,min-width-1440px */
@media (min-width: 90rem) {
	.navigation-overlay__secondary-menu {
		padding-bottom: var(--space-100--rem);
	}
}

.navigation-overlay__secondary-menu__list {
	list-style: none;
	margin: 0;
	padding: 0;
	text-align: center;
}

/* from-small,tablet-p,min-width-768px */
@media (min-width: 48rem) {
	.navigation-overlay__secondary-menu__list {
		display: flex;
		justify-content: space-between;
	}
}

/* up-to-small,max-width-767px */
@media (max-width: 47.9375rem) {
	.navigation-overlay__secondary-menu__list__item:not(:last-child) {
		margin-bottom: var(--space-20--rem);
	}
}

.navigation-overlay__secondary-menu__list__item :any-link:hover {
	color: var(--color-alpha-sulphur-yellow-70);
}

.navigation-overlay__secondary-menu__list__item--current :any-link {
	color: var(--color-alpha-sulphur-yellow-70);
	text-decoration: none;
}

.navigation-overlay__text {
	color: var(--color-white);
	margin-bottom: var(--space-45--rem);
	text-align: center;
}

/* from-small,tablet-p,min-width-768px */
@media (min-width: 48rem) {
	.navigation-overlay__text {
		margin-right: auto;
		margin-bottom: var(--space-90--rem);
		margin-left: auto;
		/* spans 8 cols */
		width: calc(((100% - (11 * var(--column-gap))) / (12 / 8)) + ((8 - 1) * var(--column-gap)));
	}
}

.navigation-overlay__social {
	color: var(--color-white);
	text-align: center;
}

.navigation-overlay__social :any-link:hover {
	color: currentColor;
	opacity: 0.7;
}

.navigation-overlay__social .icon-instagram {
	display: inline-block;
	width: var(--space-20--rem);
	height: var(--space-20--rem);
}

/* from-large,min-width-1440px */
@media (min-width: 90rem) {
	.navigation-overlay__social .icon-instagram {
		width: var(--space-25--rem);
		height: var(--space-25--rem);
	}
}
