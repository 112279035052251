.l-error {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	position: fixed;
	width: 100%;
	height: 100vh;
}

.l-error__container {
	padding-right: var(--space-20--px);
	padding-left: var(--space-20--px);
	max-width: 17.5rem;
}

/* from-large,min-width-1440px */
@media (min-width: 90rem) {
	.l-error__container {
		max-width: 25rem;
	}
}

/* #region Main */
.l-error__main {
	margin-top: var(--space-45--rem);
}

/* from-large,min-width-1440px */
@media (min-width: 90rem) {
	.l-error__main {
		margin-top: var(--space-90--rem);
	}
}
/* #endregion Main */
