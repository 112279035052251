.primary-navigation {
	list-style: none;
	margin: 0;
	padding: 0;
	text-align: center;
}

/* from-small,tablet-p,min-width-768px */
@media (min-width: 48rem) {
	.primary-navigation {
		display: flex;
		justify-content: center;
		align-items: center;
	}
}

/* up-to-small,max-width-767px */
@media (max-width: 47.9375rem) {
	.primary-navigation__item:not(:last-child) {
		margin-bottom: var(--space-20--rem);
	}
}

/* from-small,tablet-p,min-width-768px */
@media (min-width: 48rem) {
	.primary-navigation__item:not(:last-child) {
		margin-right: var(--space-45--px);
	}
}

/* from-large,min-width-1440px */
@media (min-width: 90rem) {
	.primary-navigation__item:not(:last-child) {
		margin-right: 110px;
	}
}

.primary-navigation__link {
	color: currentColor;
}

.primary-navigation__link:hover,
.primary-navigation__item--current .primary-navigation__link {
	color: currentColor;
	opacity: 0.7;
}

.primary-navigation__item--current .primary-navigation__link {
	text-decoration: none;
}
