.pricing-and-bookings {
	display: grid;
	grid-column-gap: var(--column-gap);
	grid-template-columns: repeat(3, 14.375rem);
	margin-bottom: var(--space-20--rem);
	overflow-x: scroll;
	padding-right: var(--space-20--px);
	padding-left: var(--space-20--px);
}

/* from-small,tablet-p,min-width-768px */
@media (min-width: 48rem) {
	.pricing-and-bookings {
		grid-template-columns: repeat(3, 1fr);
		padding-right: var(--space-60--px);
		padding-left: var(--space-60--px);
	}
}

/* from-large,min-width-1440px */
@media (min-width: 90rem) {
	.pricing-and-bookings {
		margin-right: auto;
		margin-left: auto;
		padding-right: 0;
		padding-left: 0;
		max-width: 62.1875rem;
	}
}

.pricing-and-bookings hr {
	margin: 0;
	width: 100%;
}

/* #region Item */
.pricing-and-booking__item {
	grid-row: 1 / 5;
}

.pricing-and-booking__item {
	background-color: var(--color-light-grey);
	display: grid;
	row-gap: 0;
	align-self: baseline;
}

@supports (grid-template-rows: subgrid) {
	.pricing-and-booking__item {
		background-color: var(--color-light-grey);
		display: grid;
		row-gap: 0;
		grid-template-rows: subgrid;
		grid-template-columns: subgrid;
		align-self: auto;
	}
}
/* #endregion Item */

/* #region Header */
.pricing-and-booking__header {
	grid-row: 1;
	grid-column: 1;
	padding: 2.1875rem var(--space-10--px) 0 var(--space-10--px);
	text-align: center;
}

/* from-large,min-width-1440px */
@media (min-width: 90rem) {
	.pricing-and-booking__header {
		padding: calc(4.375rem) calc(var(--space-10--px) * 2) 0 calc(var(--space-10--px) * 2);
	}
}
/* #endregion Header */

/* #region Price */
.pricing-and-booking__price {
	display: flex;
	flex-flow: column;
	grid-row: 2;
	grid-column: 1;
	padding: var(--space-30--rem) 0;
	align-items: center;
}

/* from-large,min-width-1440px */
@media (min-width: 90rem) {
	.pricing-and-booking__price {
		padding: var(--space-40--rem) 0;
	}
}
/* #endregion Price */

/* #region Selling Points */
.pricing-and-booking__selling-points {
	grid-row: 3;
	grid-column: 1;
	list-style: none;
	margin: 0;
	padding: 0 var(--space-10--px);
}

/* from-large,min-width-1440px */
@media (min-width: 90rem) {
	.pricing-and-booking__selling-points {
		padding: 0 var(--space-20--px);
	}
}

.pricing-and-booking__selling-points hr {
	margin: 0;
}

.pricing-and-booking__selling-point {
	display: flex;
	margin-left: 0;
}

.pricing-and-booking__selling-point:not(:first-child) {
	padding-top: var(--space-10--rem);
}

.pricing-and-booking__selling-point:not(:last-child) {
	border-bottom: var(--space-1--px) solid var(--color-grey);
	padding-bottom: var(--space-10--rem);
}

/* from-large,min-width-1440px */
@media (min-width: 90rem) {
	.pricing-and-booking__selling-point:not(:first-child) {
		padding-top: var(--space-20--rem);
	}

	.pricing-and-booking__selling-point:not(:last-child) {
		padding-bottom: var(--space-20--rem);
	}
}

.pricing-and-booking__selling-point .icon-checkmark {
	fill: var(--color-slate);
	width: 1rem;
	height: 1.5rem;
}

/* from-large,min-width-1440px */
@media (min-width: 90rem) {
	.pricing-and-booking__selling-point .icon-checkmark {
		width: 1.25rem;
		height: 1.75rem;
	}
}

.pricing-and-booking__selling-point__text {
	margin-left: var(--space-10--px);
	width: 100%;
}

/* from-large,min-width-1440px */
@media (min-width: 90rem) {
	.pricing-and-booking__selling-point__text {
		margin-left: var(--space-20--px);
	}
}
/* #endregion Selling Points */

/* #region Footer */
.pricing-and-booking__footer {
	display: flex;
	flex-flow: column;
	grid-row: 4;
	grid-column: 1;
	padding-bottom: var(--space-10--rem);
}

/* from-large,min-width-1440px */
@media (min-width: 90rem) {
	.pricing-and-booking__footer {
		padding-bottom: var(--space-20--rem);
	}
}

.pricing-and-booking__footer--no-footnotes {
	padding-bottom: var(--space-20--rem);
}

/* from-large,min-width-1440px */
@media (min-width: 90rem) {
	.pricing-and-booking__footer--no-footnotes {
		padding-bottom: var(--space-40--rem);
	}
}

.pricing-and-booking__footer hr {
	margin: var(--space-40--rem) 0 var(--space-20--rem) 0;
}

/* from-large,min-width-1440px */
@media (min-width: 90rem) {
	.pricing-and-booking__footer hr {
		margin-bottom: var(--space-40--rem);
	}
}
/* #endregion Footer */

/* #region Buttons */
.pricing-and-booking__buttons {
	display: flex;
	flex-flow: column;
	margin: 0 auto;
}

*.pricing-and-booking__buttons .button:first-of-type {
	margin-bottom: var(--space-10--rem);
}

/* from-large,min-width-1440px */
@media (min-width: 90rem) {
	*.pricing-and-booking__buttons .button:first-of-type {
		margin-bottom: var(--space-20--rem);
	}
}
/* #endregion Buttons */

/* #region Footnotes */
.pricing-and-booking__footnotes {
	list-style: none;
	margin: 0;
	padding: var(--space-15--rem) var(--space-10--px) 0 var(--space-10--px);
}

/* from-large,min-width-1440px */
@media (min-width: 90rem) {
	.pricing-and-booking__footnotes {
		padding: var(--space-30--rem) var(--space-20--px) 0 var(--space-20--px);
	}
}

.pricing-and-booking__footnote:not(:last-child) {
	margin-bottom: var(--space-10--rem);
}
/* #endregion Footnotes */

/* #region Info */
.pricing-and-bookings-info {
	color: var(--color-grey);
	font-style: italic;
	text-align: center;
}

/* from-small,tablet-p,min-width-768px */
@media (min-width: 48rem) {
	.pricing-and-bookings-info {
		display: none;
	}
}
/* #endregion Info */
