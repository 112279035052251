.banner {
	padding-top: var(--space-45--rem);
	padding-bottom: var(--space-45--rem);
}

/* from-small,tablet-p,min-width-768px */
@media (min-width: 48rem) {
	.banner {
		/* spans 2 cols */
		margin-left: calc(((100% - (11 * var(--column-gap))) / (12 / 2)) + ((2 - 1) * var(--column-gap)));
		/* spans 6 cols */
		width: calc(((100% - (11 * var(--column-gap))) / (12 / 6)) + ((6 - 1) * var(--column-gap)));
	}
}

/* from-large,min-width-1440px */
@media (min-width: 90rem) {
	.banner {
		padding-top: var(--space-90--rem);
		padding-bottom: var(--space-90--rem);
	}
}

/* from-large,min-width-1440px */
@media (min-width: 90rem) {
	.banner > p {
		margin-top: var(--space-40--rem);
		margin-bottom: var(--space-40--rem);
	}
}
