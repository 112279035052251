.pagination {
	display: flex;
	list-style-type: none;
	margin: 0;
	padding: 0;
	justify-content: center;
}

.pagination__item {
	box-sizing: border-box;
	display: flex;
	list-style-type: none;
	justify-content: center;
	align-items: center;
	width: calc((30px + 30px + 1.875rem) / 3);
	height: calc((30px + 30px + 1.875rem) / 3);
}

/* from-large,min-width-1440px */
@media (min-width: 90rem) {
	.pagination__item {
		width: calc((40px + 40px + 2.5rem) / 3);
		height: calc((40px + 40px + 2.5rem) / 3);
	}
}

.pagination__item--dots {
	text-decoration: none;
}

.pagination__item :any-link {
	display: inline-flex;
	justify-content: center;
	align-items: center;
	transition: color 96ms linear;
	width: 100%;
	height: 100%;
}

.pagination__item:not(.pagination__arrow) :any-link {
	padding-bottom: 2px;
	text-decoration: underline 1px;
	text-underline-offset: 3px;
}

.pagination__item:hover :any-link {
	color: var(--color-alpha-slate-70);
	text-decoration: none;
}

.pagination__item .icon {
	fill: currentColor;
	width: calc((12px + 12px + 1rem) / 3);
	height: calc((10px + 10px + 1rem) / 3);
}

.pagination__item--left .icon {
	transform: rotate(-180deg);
}

.pagination__item--current {
	background-color: var(--color-slate);
	color: var(--color-white);
	padding-bottom: 2px;
	text-decoration: none;
}

.pagination__arrow :any-link {
	box-sizing: border-box;
	color: var(--color-slate);
	transition:
		background-color 96ms linear,
		border-color 96ms linear,
		color 96ms linear;
}

.pagination__arrow :any-link:is(:hover, :focus) {
	background-color: var(--color-slate);
	border-color: var(--color-slate);
	color: var(--color-white);
	opacity: 1;
}
