.site-footer {
	padding: var(--space-40--rem) var(--space-20--px);
}

/* from-small,tablet-p,min-width-768px */
@media (min-width: 48rem) {
	.site-footer {
		padding: var(--space-90--rem) var(--space-20--px);
	}
}

.site-footer__wrapper {
	margin: 0 auto;
	max-width: 62.1875rem;
}

/* #region Primary Navigation */
.site-footer__primary-navigation {
	list-style: none;
	margin: 0;
	padding: 0;
	text-align: center;
}

/* from-small,tablet-p,min-width-768px */
@media (min-width: 48rem) {
	.site-footer__primary-navigation {
		display: flex;
		padding: 0 var(--space-40--rem);
		justify-content: space-between;
	}
}

/* from-large,min-width-1440px */
@media (min-width: 90rem) {
	.site-footer__primary-navigation {
		padding: 0;
	}
}

.site-footer__primary-navigation__item:not(*:last-of-type) {
	padding-bottom: var(--space-20--rem);
}

.site-footer__primary-navigation__item :any-link:hover {
	color: var(--color-alpha-sulphur-yellow-70);
}

.site-footer__primary-navigation__item--current :any-link {
	color: var(--color-alpha-sulphur-yellow-70);
	text-decoration: none;
}
/* #endregion Primary Navigation */

/* #region Info Block */
.site-footer__info-blocks {
	list-style: none;
	margin: var(--space-40--rem) 0;
	padding: 0;
}

/* from-small,tablet-p,min-width-768px */
@media (min-width: 48rem) {
	.site-footer__info-blocks {
		display: flex;
		margin: var(--space-90--rem) 0 var(--space-45--rem) 0;
		justify-content: center;
	}
}

/* from-large,min-width-1440px */
@media (min-width: 90rem) {
	.site-footer__info-blocks {
		margin: var(--space-100--rem) 0 var(--space-40--rem) 0;
	}
}

.site-footer__info-blocks__item {
	border-radius: var(--radius-10--px);
	box-sizing: border-box;
	padding: var(--space-15--rem);
}

/* up-to-small,max-width-767px */
@media (max-width: 47.9375rem) {
	.site-footer__info-blocks__item:first-child {
		margin-bottom: var(--space-20--rem);
	}
}

/* from-small,tablet-p,min-width-768px */
@media (min-width: 48rem) {
	.site-footer__info-blocks__item {
		width: calc(100% / 728 * 272);
	}
}

/* from-large,min-width-1440px */
@media (min-width: 90rem) {
	.site-footer__info-blocks__item {
		padding: var(--space-30--rem);
		width: calc(100% / 995 * 400);
	}
}

/* from-small,tablet-p,min-width-768px */
@media (min-width: 48rem) {
	.site-footer__info-blocks__item:first-child {
		margin-right: var(--space-25--px);
	}
}
/* #endregion Info Block */

/* #region Partners */
.site-footer__partners-wrapper {
	text-align: center;
}

.site-footer__partners {
	display: grid;
	grid-row-gap: var(--column-gap);
	grid-column-gap: var(--space-25--px);
	grid-template-columns: repeat(2, 1fr);
	list-style: none;
	margin: 0;
	padding: 0;
}

/* from-small,tablet-p,min-width-768px */
@media (min-width: 48rem) {
	.site-footer__partners {
		grid-column-gap: var(--column-gap);
		grid-template-columns: repeat(4, 1fr);
	}
}

/* from-large,min-width-1440px */
@media (min-width: 90rem) {
	.site-footer__partners {
		margin-top: var(--space-40--rem);
	}
}

.site-footer__partners__item {
	transition: opacity 96ms linear;
}

.site-footer__partners__item:is(:hover, :focus) {
	opacity: 0.7;
}

.site-footer__partners__image {
	width: 100%;
}
/* #endregion Partners */

/* #region Secondary Navigation */
.site-footer__secondary-wrapper {
}

/* from-small,tablet-p,min-width-768px */
@media (min-width: 48rem) {
	.site-footer__secondary-wrapper {
		display: flex;
		margin-top: var(--space-45--rem);
		justify-content: space-between;
	}
}

/* from-large,min-width-1440px */
@media (min-width: 90rem) {
	.site-footer__secondary-wrapper {
		margin-top: var(--space-40--rem);
	}
}

/* up-to-small,max-width-767px */
@media (max-width: 47.9375rem) {
	.site-footer__copyright {
		margin: var(--space-40--rem);
		text-align: center;
	}
}

.site-footer__secondary-navigation {
	display: flex;
	flex-flow: wrap;
	list-style: none;
	margin: 0;
	padding: 0;
	justify-content: space-around;
}

/* from-small,tablet-p,min-width-768px */
@media (min-width: 48rem) {
	.site-footer__secondary-navigation__item:not(*:last-of-type) {
		margin-right: var(--space-20--rem);
	}
}

.site-footer__secondary-navigation__item :any-link:hover {
	color: var(--color-alpha-sulphur-yellow-70);
}

.site-footer__secondary-navigation__item--current :any-link {
	color: var(--color-alpha-sulphur-yellow-70);
	text-decoration: none;
}
/* #endregion Secondary Navigation */
