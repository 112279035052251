.skip-to-main-content-link {
	box-sizing: border-box;
	clip-path: polygon(0 0, 0 0, 0 0, 0 0);
	margin: 0;
	padding: 0;
	pointer-events: none;
	position: absolute;
	z-index: 9999999999; /* Absolutely has to be highest of theme. */
}

.skip-to-main-content-link:focus {
	background-color: #f5f5f5;
	border: 1px solid #cccccc;
	clip-path: none;
	color: blue;
	font-family: monospace;
	font-size: 1.1875rem;
	padding: var(--space-10--px);
	pointer-events: auto;
	text-align: center;
	text-decoration: none;
	top: var(--space-10--px);
	left: var(--space-20--px);
}
