.image {
	margin: 0;
}

.image__source {
	display: block;
	width: 100%;
}

.image__caption {
	margin-top: var(--space-10--rem);
	text-align: center;
}

/* from-large,min-width-1440px */
@media (min-width: 90rem) {
	.image__caption {
		margin-top: var(--space-20--rem);
	}
}
