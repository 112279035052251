.arrow-link {
	color: currentColor;
	display: inline-flex;
	text-decoration: underline 1px;
	text-underline-offset: 2px;
}

.arrow-link:hover {
	color: var(--color-alpha-slate-70);
}

.arrow-link:hover:focus {
	color: var(--color-slate);
}

.arrow-link:focus {
	text-decoration: underline;
}

.arrow-link .icon-arrow {
	fill: currentColor;
	flex-shrink: 0;
	margin-right: var(--space-10--rem);
	transform: translate(0.0625rem, 0.35rem);
	transition: transform 256ms ease-out;
	width: 0.6875rem;
	height: 0.5625rem;
}

.arrow-link:hover .icon-arrow {
	transform: translate(0.25rem, 0.35rem);
}

/* from-large,min-width-1440px */
@media (min-width: 90rem) {
	.arrow-link .icon-arrow {
		transform: translate(0, 0.4rem);
		width: 0.875rem;
		height: 0.75rem;
	}

	.arrow-link:hover .icon-arrow {
		transform: translate(0.25rem, 0.4rem);
	}
}

/* #region Theme variations */
.theme-slate .arrow-link:hover {
	color: var(--color-alpha-sulphur-yellow-70);
}

.theme-slate .arrow-link:hover:focus {
	color: var(--color-sulphur-yellow);
}

.theme-white .arrow-link:hover {
	color: var(--color-alpha-slate-70);
}

.theme-white .arrow-link:hover:focus {
	color: var(--color-slate);
}
/* #endregion Theme variations */
