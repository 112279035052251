.wysiwyg {
}

/* from-small,tablet-p,min-width-768px */
@media (min-width: 48rem) {
	.wysiwyg {
		margin-right: auto;
		margin-left: auto;
		/* spans 8 cols */
		width: calc(((100% - (11 * var(--column-gap))) / (12 / 8)) + ((8 - 1) * var(--column-gap)));
	}
}

/* from-small,tablet-p,min-width-768px */
@media (min-width: 48rem) {
	.wysiwyg table {
		min-width: 100%;
	}
}
