.accordion {
	display: block;
	width: 100%;
}

/* from-small,tablet-p,min-width-768px */
@media (min-width: 48rem) {
	.accordion {
		flex-basis: calc(50% - calc(var(--column-gap) / 2));
	}
}

/* #region Wrapper */
.accordion-wrapper {
}

/* from-small,tablet-p,min-width-768px */
@media (min-width: 48rem) {
	.accordion-wrapper {
		display: flex;
		justify-content: space-between;
	}
}
/* #endregion Wrapper */

/* #region Item */
.accordion__item {
	border-bottom: var(--space-1--px) solid var(--color-grey);
}
/* #endregion Item */

/* #region Heading */
.accordion-heading {
	margin-bottom: var(--space-45--rem);
}

/* from-small,tablet-p,min-width-768px */
@media (min-width: 48rem) {
	.accordion-heading {
		flex-basis: calc(50% - calc(var(--column-gap) / 2));
		margin-bottom: 0;
	}
}
/* #endregion Heading */

/* #region Trigger */
.accordion__trigger__wrapper {
	margin-bottom: 0;
}

.accordion__trigger {
	-mrh-resets: button; /* button reset */
	color: var(--color-slate);
	cursor: default;
	display: flex;
	padding: var(--space-15--rem) 0;
	justify-content: space-between;
	align-items: center;
	width: 100%;
}

/* from-large,min-width-1440px */
@media (min-width: 90rem) {
	.accordion__trigger {
		padding-top: var(--space-20--rem);
		padding-bottom: var(--space-20--rem);
	}
}

.accordion__trigger span {
	pointer-events: none;
}

.accordion__trigger:is(:hover, :focus) {
	cursor: pointer;
}

/* #endregion Trigger */

/* #region Title */
.accordion__trigger__title {
	margin-right: var(--space-10--rem);
	position: relative;
	text-align: left;
}

/* from-large,min-width-1440px */
@media (min-width: 90rem) {
	.accordion__trigger__title {
		margin-right: var(--space-20--rem);
	}
}
/* #endregion Title */

/* #region Icon */
.accordion__trigger__icon {
	flex-grow: 0;
	flex-shrink: 0;
	transition: transform 320ms ease-in-out;
	width: 0.6875rem;
	height: 0.6875rem;
}

/* from-large,min-width-1440px */
@media (min-width: 90rem) {
	.accordion__trigger__icon {
		width: 0.875rem;
		height: 0.875rem;
	}
}

.accordion__trigger:hover {
	text-decoration: underline;
}

.accordion__trigger[aria-expanded="true"] .accordion__trigger__icon {
	transform: rotate(180deg);
}

.accordion__trigger[aria-expanded="false"]:hover .accordion__trigger__icon {
	transform: translate3d(0, 0.25rem, 0) rotate(0);
}

.accordion__trigger[aria-expanded="true"]:hover .accordion__trigger__icon {
	transform: translate3d(0, -0.25rem, 0) rotate(180deg);
}

.accordion__trigger__icon .icon {
	display: block;
	fill: currentColor;
	transform: rotate(90deg);
	width: 100%;
	height: 100%;
}

.accordion__trigger[aria-expanded="true"] .accordion__trigger__icon {
	transform: rotate(180deg);
}
/* #endregion Icon */

/* #region Panel */
.accordion__panel {
	color: var(--color-slate);
	padding-bottom: var(--space-15--rem);
}

/* from-large,min-width-1440px */
@media (min-width: 90rem) {
	.accordion__panel {
		padding-top: 0.3125rem;
		padding-bottom: var(--space-20--rem);
	}
}

.accordion__panel__inner p {
	color: var(--color-black);
	font-size: inherit;
}

.accordion__panel[hidden] {
	display: none;
}

@supports (content-visibility: hidden) {
	.accordion__panel[hidden="until-found"] {
		content-visibility: hidden;
		display: block;
		padding: 0;
	}
}
/* #endregion Panel */
