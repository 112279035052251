.vimeo-embed {
	background-color: var(--color-grey);
	padding-top: 56.25%;
	position: relative;
}

.vimeo-embed__iframe {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 100%;
}
