.testimonials {
	display: block;
	overflow: hidden;
	position: relative;
	width: 100%;
}

/* from-small,tablet-p,min-width-768px */
@media (min-width: 48rem) {
	.testimonials {
		margin: 0 auto;
		/* spans 8 cols */
		width: calc(((100% - (11 * var(--column-gap))) / (12 / 8)) + ((6 - 1) * var(--column-gap)));
	}
}

/* #region Items */
.testimonials__items {
	display: flex;
	list-style: none;
	margin: 0;
	padding: 0;
	align-items: center;
}

.testimonials__item {
	flex-shrink: 0;
	text-align: center;
	width: 100%;
}

.testimonials__quote {
	margin: 0;
}

.testimonials__quote__blockquote {
	margin: 0 0 var(--space-20--rem) 0;
}

/* from-large,min-width-1440px */
@media (min-width: 90rem) {
	.testimonials__quote__blockquote {
		margin-bottom: var(--space-45--rem);
	}
}

.testimonials__quote__author p {
	margin: 0;
}

.testimonials__quote__author__info {
	color: var(--color-grey);
}
/* #endregion Items */

/* #region Buttons */
.testimonials__buttons {
	display: flex;
	margin: 0 auto;
	padding-top: 2.1875rem;
	justify-content: space-between;
	align-items: center;
	width: 5rem;
}

/* from-large,min-width-1440px */
@media (min-width: 90rem) {
	.testimonials__buttons {
		padding-top: var(--space-45--rem);
		width: 6.5625rem;
	}
}

.testimonials__button {
	-mrh-resets: button; /* button reset */
	background-color: var(--color-white);
	display: flex;
	justify-content: center;
	align-items: center;
	transition:
		background-color 96ms linear,
		border-color 96ms linear,
		color 96ms linear;
	width: calc((30px + 30px + 1.875rem) / 3);
	height: calc((30px + 30px + 1.875rem) / 3);
}

/* from-large,min-width-1440px */
@media (min-width: 90rem) {
	.testimonials__button {
		width: calc((40px + 40px + 2.5rem) / 3);
		height: calc((40px + 40px + 2.5rem) / 3);
	}
}

.testimonials__button:hover {
	background-color: var(--color-slate);
	border-color: var(--color-slate);
	color: var(--color-white);
}

.testimonials__button .icon {
	fill: currentColor;
	margin-bottom: var(--space-1--px);
	pointer-events: none;
	width: calc((12px + 12px + 1rem) / 3);
	height: calc((10px + 10px + 1rem) / 3);
}

.testimonials__button.testimonials__button--previous .icon {
	transform: rotate(180deg);
}
/* #endregion Buttons */

/* #region NO JS */
.testimonials-no-js {
	display: block;
	overflow-x: auto;
	overflow-y: hidden;
	scroll-snap-type: x mandatory;
}

.testimonials-no-js__container {
	margin-bottom: var(--space-30--rem);
	width: 100%;
	height: 100%;
}

/* from-large,min-width-1440px */
@media (min-width: 90rem) {
	.testimonials-no-js__container {
		margin-bottom: var(--space-40--rem);
	}
}

.testimonials-no-js__items {
	display: flex;
	flex-direction: row;
	list-style: none;
	margin: 0;
	padding: 0;
	justify-items: center;
	white-space: nowrap;
}

.testimonials-no-js__item {
	display: inline-block;
	flex-basis: 100%;
	flex-grow: 0;
	flex-shrink: 0;
	flex-flow: column;
	align-items: center;
	scroll-snap-align: start;
	text-align: center;
	white-space: initial;
	width: 100%;
	height: 100%;
}
/* #endregion NO JS */
