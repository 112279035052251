.site-header {
	padding-top: var(--space-20--rem);
	padding-bottom: var(--space-30--rem);
	position: relative;
}

/* from-small,tablet-p,min-width-768px */
@media (min-width: 48rem) {
	.site-header {
		padding-bottom: var(--space-90--rem);
	}
}

.site-header__top {
	box-sizing: border-box;
	display: flex;
	margin-bottom: var(--space-30--rem);
	padding-right: var(--space-20--px);
	padding-left: var(--space-20--px);
	justify-content: center;
	align-items: center;
}

/* from-large,min-width-1440px */
@media (min-width: 90rem) {
	.site-header__top {
		margin-right: auto;
		margin-bottom: 5rem;
		margin-left: auto;
		padding-right: 0;
		padding-left: 0;
		max-width: 62.1875rem;
	}
}

.site-header--in-overlay .site-header__top {
	color: var(--color-white);
}

/* #region Logo */
.site-header__logo {
	flex-basis: auto;
	flex-grow: 1;
	flex-shrink: 0;
	order: 1;
	padding-left: var(--space-20--px);
}

/* from-small,tablet-p,min-width-768px */
@media (min-width: 48rem) {
	.site-header__logo {
		order: 0;
		padding-left: 0;
	}
}

.site-header__logo__link {
	display: block;
	transition: opacity 96ms linear;
	width: 6.5rem;
	height: 1.25rem;
}

.site-header__logo__link:hover {
	color: currentColor;
	opacity: 0.7;
}

.site-header__logo svg {
	display: block;
	width: 100%;
	height: 100%;
}
/* #endregion Logo */

/* #region Book Now */
.site-header__book-now {
	order: 2;
}

/* from-small,tablet-p,min-width-768px */
@media (min-width: 48rem) {
	.site-header__book-now {
		order: 1;
	}
}
/* #endregion Book Now */

/* #region Menu Butoon */
.site-header__menu-button {
	order: 0;
}

/* from-small,tablet-p,min-width-768px */
@media (min-width: 48rem) {
	.site-header__menu-button {
		order: 2;
		padding-left: var(--space-20--px);
	}
}

/* from-large,min-width-1440px */
@media (min-width: 90rem) {
	.site-header__menu-button {
		padding-left: var(--space-30--px);
	}
}

.site-header__hamburger {
	-mrh-resets: button;
	display: block;
	position: relative;
	transition:
		opacity 96ms linear,
		color 96ms linear;
	width: 1.25rem;
	height: 1.25rem;
}

.site-header__hamburger:hover {
	opacity: 0.7;
}

.site-header__hamburger > span {
	background-color: currentColor;
	display: block;
	position: absolute;
	top: 0.5625rem;
	left: 0;
	transition:
		transform 256ms ease-in-out,
		opacity 128ms linear;
	width: 100%;
	height: 0.125rem;
}

.site-header__hamburger > span:first-child {
	transform: translateY(-0.375rem);
}

.site-header__hamburger > span:nth-child(2) {
	opacity: 1;
}

.site-header__hamburger > span:last-child {
	transform: translateY(0.375rem);
}

.is-showing-modal-dialog .site-header__hamburger > span:first-child,
.site-header--fallback .site-header__hamburger > span:first-child {
	transform: rotate(45deg);
}

.is-showing-modal-dialog .site-header__hamburger > span:nth-child(2),
.site-header--fallback .site-header__hamburger > span:nth-child(2) {
	opacity: 0;
}

.is-showing-modal-dialog .site-header__hamburger > span:last-child,
.site-header--fallback .site-header__hamburger > span:last-child {
	transform: rotate(-45deg);
}
/* #endregion Menu Button */
