.newsletter-subscribe {
}

/* from-small,tablet-p,min-width-768px */
@media (min-width: 48rem) {
	.newsletter-subscribe {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
	}
}

/* up-to-small,max-width-767px */
@media (max-width: 47.9375rem) {
	.newsletter-subscribe__column:not(:last-child) {
		margin-bottom: var(--space-45--rem);
	}
}

/* from-small,tablet-p,min-width-768px */
@media (min-width: 48rem) {
	.newsletter-subscribe__column {
		width: calc(50% - var(--column-gap) / 2);
	}
}

.newsletter-subscribe__form:not(:first-child) {
	margin-top: var(--space-20--rem);
}

/* from-small,tablet-p,min-width-768px */
@media (min-width: 48rem) {
	.newsletter-subscribe__form:not(:first-child) {
		margin-top: var(--space-45--rem);
	}
}

/* from-small,tablet-p,min-width-768px */
@media (min-width: 48rem) {
	.newsletter-subscribe__form__fields {
		display: flex;
		margin-top: var(--space-10--rem);
	}

	.newsletter-subscribe__form__fields .form__field-wrapper {
		margin: 0;
		margin-right: var(--column-gap);
		width: calc(100% / 485 * 314 - var(--column-gap) / 2);
	}
}
