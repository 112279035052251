.carousel {
	aspect-ratio: 3 / 2;
	display: flex;
	position: relative;
}

.carousel[data-aspect-ratio="3-2"] {
	aspect-ratio: 3 / 2;
}

.carousel[data-aspect-ratio="16-9"] {
	aspect-ratio: 16 / 9;
}

.carousel[data-aspect-ratio="2-3"] {
	aspect-ratio: 2 / 3;
}

.carousel[data-aspect-ratio="9-16"] {
	aspect-ratio: 9 / 16;
}

.carousel__container {
	width: 100%;
	height: 100%;
}

/* #region Items */
.carousel__items {
	list-style: none;
	margin: 0;
	padding: 0;
	height: 100%;
}

.carousel__item {
	display: block;
	opacity: 0;
	position: absolute;
	top: 0;
	left: 0;
	transition-duration: 640ms;
	transition-property: opacity;
	width: 100%;
	height: 100%;
}

.carousel__item[data-carousel-item-current] {
	opacity: 1;
}
/* #endregion Items */

/* #region Image */
.carousel__figure {
	margin: 0;
	position: relative;
	height: 100%;
}

.carousel__image {
	width: 100%;
	height: auto;
}

@supports (object-fit: cover) {
	.carousel__image {
		object-fit: cover;
		height: 100%;
	}
}

.carousel__image__caption {
	margin-top: var(--space-10--rem);
	position: absolute;
	text-align: center;
	width: 100%;
}

/* from-large,min-width-1440px */
@media (min-width: 90rem) {
	.carousel__image__caption {
		margin-top: var(--space-20--rem);
	}
}
/* #endregion Image */

/* #region Buttons */
.carousel__buttons {
	display: flex;
	position: absolute;
	right: 0;
	bottom: 0;
	z-index: 100;
}

.carousel__button {
	-mrh-resets: button; /* button reset */
	background-color: var(--color-white);
	display: flex;
	justify-content: center;
	align-items: center;
	transition:
		background-color 96ms linear,
		border-color 96ms linear,
		color 96ms linear;
	width: calc((30px + 30px + 1.875rem) / 3);
	height: calc((30px + 30px + 1.875rem) / 3);
}

/* from-large,min-width-1440px */
@media (min-width: 90rem) {
	.carousel__button {
		width: calc((40px + 40px + 2.5rem) / 3);
		height: calc((40px + 40px + 2.5rem) / 3);
	}
}

.carousel__button:hover {
	background-color: var(--color-slate);
	border-color: var(--color-slate);
	color: var(--color-white);
}

.carousel__button .icon {
	fill: currentColor;
	margin-bottom: var(--space-1--px);
	pointer-events: none;
	width: calc((12px + 12px + 1rem) / 3);
	height: calc((10px + 10px + 1rem) / 3);
}

.carousel__button.carousel__button--previous .icon {
	transform: rotate(180deg);
}
/* #endregion Buttons */

/* #region NO JS */
.carousel-no-js {
	display: block;
	overflow-x: auto;
	overflow-y: hidden;
	scroll-snap-type: x mandatory;
}

.carousel-no-js__container {
	margin-bottom: var(--space-30--rem);
	width: 100%;
	height: 100%;
}

/* from-large,min-width-1440px */
@media (min-width: 90rem) {
	.carousel-no-js__container {
		margin-bottom: var(--space-40--rem);
	}
}

.carousel-no-js__items {
	list-style: none;
	margin: 0;
	padding: 0;
	white-space: nowrap;
}

.carousel-no-js__item {
	display: inline-flex;
	flex-flow: column;
	scroll-snap-align: start;
	width: 100%;
	height: 100%;
}

.carousel-no-js__image {
	width: 100%;
	height: auto;
}

@supports (object-fit: cover) {
	.carousel-no-js__image {
		object-fit: cover;
		height: 100%;
	}
}
/* #endregion NO JS */
