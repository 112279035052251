.hero-detail {
}

/* from-small,tablet-p,min-width-768px */
@media (min-width: 48rem) {
	.hero-detail__navigation {
		padding-bottom: var(--space-40--rem);
	}
}

/* from-large,min-width-1440px */
@media (min-width: 90rem) {
	.hero-detail__navigation {
		padding-bottom: 4.375rem;
	}
}

/* from-small,tablet-p,min-width-768px */
@media (min-width: 48rem) {
	.hero-detail__main-wrapper {
		box-sizing: border-box;
		margin-top: calc(var(--space-30--rem) * -1);
		padding-right: var(--space-60--px);
		padding-left: var(--space-60--px);
	}
}

/* from-large,min-width-1440px */
@media (min-width: 90rem) {
	.hero-detail__main-wrapper {
		margin-top: -4.375rem;
		margin-right: auto;
		margin-left: auto;
		padding-right: 0;
		padding-left: 0;
		max-width: 62.1875rem;
	}
}

.hero-detail__main {
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
	min-height: 10.25rem;
}

/* from-small,tablet-p,min-width-768px */
@media (min-width: 48rem) {
	.hero-detail__main {
		min-height: 13.75rem;
	}
}

/* from-large,min-width-1440px */
@media (min-width: 90rem) {
	.hero-detail__main {
		min-height: 22.5rem;
	}
}

.hero-detail__background {
	opacity: 0.8;
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
}

.hero-detail__background__image {
	width: 100%;
	height: auto;
}

@supports (object-fit: cover) {
	.hero-detail__background__image {
		object-fit: cover;
		height: 100%;
	}
}

.hero-detail__title {
	margin: 0;
	padding: var(--space-45--rem) var(--space-20--px);
	padding-bottom: var(--space-45--rem);
	position: relative;
	text-align: center;
}

/* from-small,tablet-p,min-width-768px */
@media (min-width: 48rem) {
	.hero-detail__title {
		padding: var(--space-45--rem) var(--space-40--px);
	}
}
