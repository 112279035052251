.teaser-list {
}

/* #region List Item */
.teaser-list__item {
	color: var(--color-slate);
	display: block;
	padding-top: var(--space-10--rem);
	padding-bottom: var(--space-10--rem);
	text-decoration: none;
}

.teaser-list__item:hover {
	color: var(--color-alpha-slate-70);
}

/* from-small,tablet-p,min-width-768px */
@media (min-width: 48rem) {
	.teaser-list__item {
		display: flex;
		padding-top: var(--space-20--rem);
		padding-bottom: var(--space-20--rem);
		justify-content: space-between;
	}
}

/* from-large,min-width-1440px */
@media (min-width: 90rem) {
	.teaser-list__item {
		padding-top: var(--space-40--rem);
		padding-bottom: var(--space-40--rem);
	}
}

.teaser-list__item:first-child {
	padding-top: 0;
}

.teaser-list__item:not(:last-child) {
	border-bottom: var(--space-1--rem) solid var(--color-grey);
}
/* #endregion List Item */

/* #region Text */

/* from-small,tablet-p,min-width-768px */
@media (min-width: 48rem) {
	.teaser-list__item__text {
		flex-basis: calc(100% - (100% / 648 * 172) - var(--space-20--px));
		flex-grow: 0;
		flex-shrink: 0;
	}
}

/* from-large,min-width-1440px */
@media (min-width: 90rem) {
	.teaser-list__item__text {
		flex-basis: calc(100% - (100% / 995 * 230) - 85px);
		flex-grow: 0;
		flex-shrink: 0;
	}
}

.teaser-list__item__title {
	margin-bottom: var(--space-10--rem);
	text-decoration: underline;
}

/* from-large,min-width-1440px */
@media (min-width: 90rem) {
	.teaser-list__item__title {
		margin-bottom: var(--space-20--rem);
	}
}

.teaser-list__item:hover .teaser-list__item__title {
	text-decoration: none;
}

.teaser-list__item__text__excerpt {
	display: none;
}

.teaser-list__item__text__excerpt > p {
	margin-bottom: var(--space-10--rem);
	text-decoration: none;
}

/* from-small,tablet-p,min-width-768px */
@media (min-width: 48rem) {
	.teaser-list__item__text__excerpt {
		display: block;
	}
}

/* from-large,min-width-1440px */
@media (min-width: 90rem) {
	.teaser-list__item__text__excerpt > p {
		margin-bottom: var(--space-20--rem);
	}
}

.teaser-list__item__date {
	color: var(--color-grey);
	display: block;
}

/* from-large,min-width-1440px */
@media (min-width: 90rem) {
	.teaser-list__item__date {
		color: var(--color-grey);
		display: block;
	}
}
/* #endregion Text */

/* #region Visual */

/* from-small,tablet-p,min-width-768px */
@media (min-width: 48rem) {
	.teaser-list__item__visual {
		flex-basis: calc(100% / 648 * 172);
		flex-grow: 0;
		flex-shrink: 0;
	}
}

/* from-large,min-width-1440px */
@media (min-width: 90rem) {
	.teaser-list__item__visual {
		flex-basis: calc(100% / 995 * 230);
	}
}

.teaser-list__item__visual__picture {
	display: block;
	overflow: hidden;
}

/* up-to-small,max-width-767px */
@media (max-width: 47.9375rem) {
	.teaser-list__item__visual__picture {
		width: var(--space-100--rem);
	}
}

.teaser-list__item .teaser-list__item__visual__image {
	transition: transform 256ms ease-in-out;
	width: 100%;
}

.teaser-list__item:hover .teaser-list__item__visual__image {
	transform: scale(1.1);
}

.teaser-list__item__visual__excerpt {
	margin-top: var(--space-10--rem);
}

.teaser-list__item__visual__excerpt > p {
	margin-bottom: var(--space-10--rem);
}

/* from-small,tablet-p,min-width-768px */
@media (min-width: 48rem) {
	.teaser-list__item__visual__excerpt {
		display: none;
	}
}
/* #endregion Visual */
