* {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

body {
	font-family: var(--font-sans);
	font-size: 0.875rem;
}

/* from-small,tablet-p,min-width-768px */
@media (min-width: 48rem) {
	body {
		font-size: 1rem;
	}
}

/* from-wide,desktop,min-width-1280px */
@media (min-width: 80rem) {
	body {
		font-size: 1.125rem;
	}
}

h1,
h2,
h3,
h4,
h5,
h6 {
	margin-top: var(--space-25--rem);
	margin-bottom: var(--space-25--rem);
}

/* from-large,min-width-1440px */
@media (min-width: 90rem) {
	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		margin-top: var(--space-45--rem);
		margin-bottom: var(--space-45--rem);
	}
}

p {
	margin-top: var(--space-20--rem);
	margin-bottom: var(--space-20--rem);
}

:is(h1, h2, h3, h4, h5, h6, p):first-child {
	margin-top: 0;
}

:is(h1, h2, h3, h4, h5, h6, p):last-child {
	margin-bottom: 0;
}

:is(h1, h2, h3, h4, h5, h6, p):only-child {
	margin-top: 0;
	margin-bottom: 0;
}

h3,
h6 {
	color: var(--color-grey);
}

h5,
h6 {
	text-transform: uppercase;
}

i,
em {
	font-style: italic;
}

/* #region Type-a */
h1,
blockquote > p,
.type-a {
	font-family: var(--font-sans-narrow);
	font-size: 2.5rem; /* 40px */
	font-weight: 700;
	line-height: 2.5rem;
	text-transform: uppercase;
}

/* from-large,min-width-1440px */
@media (min-width: 90rem) {
	h1,
	blockquote > p,
	.type-a {
		font-size: 3.75rem; /* 60px */
		line-height: 3.75rem;
	}
}
/* #endregion Type-a */

/* #region Type-b */
h5,
h6,
.type-b {
	font-family: var(--font-sans);
	font-size: 0.75rem; /* 12px */
	font-style: normal;
	font-weight: 700;
	line-height: 0.9375rem; /* 15px */
}

/* from-large,min-width-1440px */
@media (min-width: 90rem) {
	h5,
	h6,
	.type-b {
		font-size: 1rem; /* 16px */
		line-height: 1.25rem; /* 20px */
	}
}
/* #endregion Type-b */

/* #region Type-c */
h4,
.type-c {
	font-family: var(--font-sans);
	font-size: 1.25rem; /* 20px */
	font-style: normal;
	font-weight: 400;
	line-height: 1.5625rem; /* 25px */
	text-transform: none;
}

/* from-large,min-width-1440px */
@media (min-width: 90rem) {
	h4,
	.type-c {
		font-size: 1.75rem; /* 28px */
		line-height: 2.25rem; /* 36px */
	}
}
/* #endregion Type-c */

/* #region Type-d */
.type-d {
	font-family: var(--font-sans);
	font-size: 1.375rem; /* 22px */
	font-style: normal;
	font-weight: 700;
	line-height: 1.75rem; /* 28px */
}

/* from-large,min-width-1440px */
@media (min-width: 90rem) {
	.type-d {
		font-size: 2.25rem; /* 36px */
		line-height: 2.875rem; /* 46px */
	}
}
/* #endregion Type-d */

/* #region Type-e */
p,
blockquote > cite,
blockquote > p > cite,
th,
td,
ol,
ul,
.type-e {
	font-family: var(--font-sans);
	font-size: 0.875rem; /* 14px */
	font-style: normal;
	font-weight: 400;
	line-height: 1.25rem; /* 20px */
}

/* from-large,min-width-1440px */
@media (min-width: 90rem) {
	p,
	blockquote > cite,
	blockquote > p > cite,
	th,
	td,
	ol,
	ul,
	.type-e {
		font-size: 1.125rem; /* 18px */
		line-height: 1.5rem; /* 24px */
	}
}

.type-e.type-e--italic {
	font-style: italic;
}
/* #endregion Type-e */

/* #region Type-f */
h2,
h3,
.type-f {
	font-family: var(--font-sans-narrow);
	font-size: 1.875rem; /* 30px */
	font-weight: 700;
	line-height: 1.875rem;
	text-transform: uppercase;
}

/* from-large,min-width-1440px */
@media (min-width: 90rem) {
	h2,
	h3,
	.type-f {
		font-size: 2.5rem; /* 40px */
		line-height: 2.5rem;
	}
}
/* #endregion Type-f */

/* #region Type-g */
.type-g {
	font-family: var(--font-sans-narrow);
	font-size: 2.8125rem; /* 45px */
	font-weight: 700;
	line-height: 2.375rem; /* 38px */
	text-transform: uppercase;
}

/* from-large,min-width-1440px */
@media (min-width: 90rem) {
	.type-g {
		font-size: 5.625rem; /* 90px */
		line-height: 4.6875rem; /* 75px */
	}
}
/* #endregion Type-g */

/* #region Type-h */
.type-h,
figure.wp-block-table figcaption,
table > caption {
	font-family: var(--font-sans-mono);
	font-size: 0.75rem; /* 12px */
	font-style: normal;
	font-weight: 400;
	line-height: 1.25rem; /* 20px */
}
/* #endregion Type-h */

blockquote {
	margin: var(--space-45--rem) var(--space-25--px);
}

/* from-large,min-width-1440px */
@media (min-width: 90rem) {
	blockquote {
		margin: var(--space-65--rem) var(--space-45--px);
	}
}

blockquote:first-child {
	margin-top: 0;
}

blockquote:last-child {
	margin-bottom: 0;
}

blockquote:only-child {
	margin-top: 0;
	margin-bottom: 0;
}

blockquote > p {
	margin-bottom: 0.3125rem; /* 5px */
}

/* from-large,min-width-1440px */
@media (min-width: 90rem) {
	blockquote > p {
		margin-bottom: var(--space-10--rem);
	}
}

blockquote > p:last-of-type {
	margin: 0;
}

blockquote > cite,
blockquote > p > cite {
	display: block;
	text-transform: none;
}

blockquote > cite {
	margin-top: 0.3125rem; /* 5px */
}

/* from-large,min-width-1440px */
@media (min-width: 90rem) {
	blockquote > cite {
		margin-top: var(--space-10--rem);
	}
}
