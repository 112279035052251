.theme-slate {
	background-color: var(--color-slate);
	color: var(--color-sulphur-yellow);
}

.theme-sulphur-yellow {
	background-color: var(--color-sulphur-yellow);
	color: var(--color-slate);
}

.theme-white {
	background-color: var(--color-background);
	color: var(--color-slate);
}

.theme-black {
	background-color: var(--color-black);
	color: var(--color-white);
}
