@media (prefers-reduced-motion: reduce) {
	*,
	*::after,
	*::before {
		animation-delay: -1ms !important;
		animation-duration: 0.01ms !important;
		animation-iteration-count: 1 !important;
		background-attachment: initial !important;
		transition-delay: -1ms !important;
		transition-duration: 0.01ms !important;
	}
}

/*
 * Give "display: none;" for elements with a "hidden" attribute specificity [0, 2, 0].
 * This will generally be higher than layout directives : "display: flex".
 * Because this is declared very early you can easily override it with ".foo[hidden]".
 */
[hidden][hidden] {
	display: none;
}

@supports (content-visibility: hidden) and (display: revert) {
	[hidden][hidden="until-found"] {
		content-visibility: hidden;
		display: revert;
	}
}

html {
	scroll-behavior: smooth;
}

@media (prefers-reduced-motion: reduce) {
	html {
		scroll-behavior: auto;
	}
}

body {
	background-color: var(--color-background);
	box-sizing: border-box;
	color: var(--color-foreground);
	overflow-wrap: break-word;
	position: relative;
	min-height: 100vh;
}

@supports (overflow-wrap: anywhere) {
	body {
		overflow-wrap: anywhere;
	}
}

textarea {
	resize: none;
}

input,
button,
textarea,
select {
	font: inherit;
}

hr {
	background-color: var(--color-grey);
	border: none;
	margin: var(--space-45--rem) 0;
	height: var(--space-1--rem);
}

/*
 from-large,min-width-1440px
 */
@media (min-width: 90rem) {
	hr {
		margin: var(--space-90--rem) 0;
	}
}

img,
video {
	display: block;
	max-width: 100%;
	height: auto;
}

table,
th,
td {
	border: 1px solid var(--color-grey);
}

table {
	border-collapse: collapse;
	box-sizing: border-box;
	caption-side: bottom;
	margin: var(--space-10--rem) 0;
	width: 100%;
}

/*
 up-to-small,max-width-767px
 */
@media (max-width: 47.9375rem) {
	table {
		min-width: 100%;
	}
}

/*
 from-small,tablet-p,min-width-768px
 */
@media (min-width: 48rem) {
	table {
		width: 100%;
		max-width: 100%;
	}
}

table:first-child {
	margin-top: 0;
}

table:last-child {
	margin-bottom: 0;
}

table > caption {
	color: var(--color-grey);
	margin-top: var(--space-10--rem);
	text-align: center;
}

th,
td {
	box-sizing: border-box;
	padding: var(--space-10--rem) var(--space-10--px);
	text-align: left;
}

/*
 from-large,min-width-1440px
 */
@media (min-width: 90rem) {
	th,
	td {
		padding: var(--space-20--rem) var(--space-20--px);
	}
}

:is(ol, ul):not([class]) {
	box-sizing: border-box;
	margin: 0 0 var(--space-25--rem) 0;
	padding-left: 1.25rem;
}

:is(ol, ul):not([class]):last-child {
	margin-bottom: 0;
}

li :is(ol, ul):not([class]) {
	margin-bottom: 0;
}

li:not([class]) {
	margin: 0;
	padding: 0 0 0.25rem 0;
	position: relative;
}

:any-link {
	color: currentColor;
	display: inline;
	text-decoration: underline;
}

:any-link:hover {
	color: var(--color-grey);
}

:any-link:is(:active, :focus) {
	text-decoration: none;
}

p :any-link {
	line-height: inherit;
}

.wp-block-table {
	margin: 0; /* Remove margin from Figure tag set by Gutenberg */
}

figure.wp-block-table figcaption {
	color: var(--color-grey);
	text-align: center;
}

.mr-table-scroll-container {
	margin-top: var(--space-25--rem);
	margin-bottom: var(--space-25--rem);
	overflow-x: auto;
	position: relative;
	width: 100%;
}

/*
 from-large,min-width-1440px
 */
@media (min-width: 90rem) {
	.mr-table-scroll-container {
		margin-top: var(--space-45--rem);
		margin-bottom: var(--space-45--rem);
	}
}
