.button {
	-mrh-resets: button; /* button reset */
	background-color: var(--color-slate);
	color: var(--color-white);
	font-family: var(--font-sans);
	font-size: 0.75rem; /* 12px */
	font-style: normal;
	font-weight: 700;
	line-height: 0.9375rem; /* 15px */
	padding: 0.4375rem var(--space-10--rem);
	transition:
		background-color 96ms linear,
		color 96ms linear;
}

/* from-large,min-width-1440px */
@media (min-width: 90rem) {
	.button {
		font-size: 1rem; /* 16px */
		line-height: 1.25rem; /* 20px */
		padding: var(--space-10--rem) 0.9375rem;
	}
}

.button:hover {
	background-color: var(--color-alpha-slate-70);
	color: var(--color-white);
}

.button:active {
	background-color: var(--color-alpha-slate-70);
	color: var(--color-alpha-white-70);
}

/* #region Active link */
.button--is-active,
.button--is-active:hover,
.button--is-active:active {
	background-color: var(--color-white);
	color: var(--color-slate);
}
/* #endregion Active link */

/* #region Grey */
.button--grey {
	background-color: var(--color-grey);
}

.button--grey:hover,
.button--grey:active {
	background-color: var(--color-alpha-grey-70);
}
/* #endregion Grey */

/* #region White */
.button--white {
	background-color: var(--color-white);
	color: var(--color-slate);
}

.button--white:hover {
	background-color: var(--color-alpha-white-70);
	color: var(--color-slate);
}

.button--white:active {
	background-color: var(--color-alpha-white-70);
	color: var(--color-alpha-slate-70);
}
/* #region White */
