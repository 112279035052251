.l-site {
}

.l-site__header {
}

.l-site__main {
	padding-bottom: var(--space-45--rem);
}

/* from-small,tablet-p,min-width-768px */
@media (min-width: 48rem) {
	.l-site__main {
		padding-bottom: var(--space-90--rem);
	}
}

.l-site__main__navigation {
	margin-top: var(--space-45--rem);
	margin-bottom: var(--space-45--rem);
}

/* from-small,tablet-p,min-width-768px */
@media (min-width: 48rem) {
	.l-site__main__navigation {
		margin-top: var(--space-90--rem);
		margin-bottom: var(--space-90--rem);
	}
}

.l-site__footer {
}
