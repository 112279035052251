.facilities-table {
	border: none;
	min-width: 40.5rem;
}

.facilities-table > tbody > tr > th,
.facilities-table > tbody > tr > td {
	border-color: var(--color-slate);
	color: var(--color-black);
	text-align: center;
	width: 25%;
}

.facilities-table > tbody > tr > td:first-child,
.facilities-table > tbody > tr > th:first-child {
	border-left: none;
	padding-left: 0;
	text-align: left;
}

.facilities-table > tbody > tr > td:last-child,
.facilities-table > tbody > tr > th:last-child {
	border-right: none;
}

.facilities-table > tbody > tr:last-child > td,
.facilities-table > tbody > tr:last-child > th {
	border-top: none;
	border-bottom: none;
}

.facilities-table > tbody > tr:first-child > td:first-child {
	border-bottom: none;
}

.facilities-table > tbody > tr:nth-child(2) > th:first-child {
	border-top: none;
}

/* #region Column Header */
.facilities-table > tbody > tr > th.facilities-table__column-header {
	color: var(--color-slate);
	padding-top: var(--space-20--rem);
	padding-bottom: var(--space-20--rem);
	text-align: center;
}

/* from-large,min-width-1440px */
@media (min-width: 90rem) {
	.facilities-table > tbody > tr > th.facilities-table__column-header {
		padding-top: var(--space-40--rem);
		padding-bottom: var(--space-40--rem);
	}
}
/* #endregion Column Header */

/* #region Button Row */
.facilities-table > tbody > tr.facilities-table__button-row > td {
	border-bottom: none;
	padding-top: var(--space-20--rem);
}

/* from-large,min-width-1440px */
@media (min-width: 90rem) {
	.facilities-table > tbody > tr.facilities-table__button-row > td {
		padding-top: var(--space-40--rem);
	}
}
/* #endregion Button Row */

/* #region Footnote Row */
.facilities-table > tbody > tr.facilities-table__footnote-row > td {
	padding-top: 0.3125rem;
	padding-right: var(--space-20--px);
	padding-bottom: 0;
	padding-left: var(--space-20--px);
	text-align: left;
	vertical-align: top;
}

/* from-large,min-width-1440px */
@media (min-width: 90rem) {
	.facilities-table > tbody > tr.facilities-table__footnote-row > td {
		padding-top: var(--space-10--rem);
	}
}

.facilities-table__footnote-row__list {
	list-style: none;
	margin: 0;
	padding: 0;
}

.facilities-table__footnote-row__list__item:not(:last-child) {
	margin-bottom: var(--space-10--rem);
}
/* #endregion Footnote Row */

/* #region Icon */
.facilities-table .icon-checkmark {
	display: inline-block;
	fill: var(--color-slate);
	width: 0.875rem;
	height: 0.875rem;
}

/* from-large,min-width-1440px */
@media (min-width: 90rem) {
	.facilities-table .icon-checkmark {
		width: 1.25rem;
		height: 1.25rem;
	}
}
/* #endregion Icon */

/* #region Scroll container */
.facilities-table-scroll-container {
	overflow-x: scroll;
	position: relative;
	width: 100%;
}
/* #endregion Scroll container */

/* #region Table Note */
.facilities-table-info {
	color: var(--color-grey);
	font-style: italic;
	margin-top: var(--space-20--rem);
	text-align: center;
}

/* from-small,tablet-p,min-width-768px */
@media (min-width: 48rem) {
	.facilities-table-info {
		display: none;
	}
}
/* #region Table Note */
